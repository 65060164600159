
.alok-logo {
  height: 35px;
  width: 35px;
  border-radius: 40px;
}

.bold-italic{
  font-weight: bold;
  font-style: oblique;
}

.title{
  font-weight: 700;
  font-style: oblique;
}

.bg-color {
  background-color: #292828;
}

.margin-padding {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .alok-logo {
    height: 55px;
    width: 55px;
    border-radius: 40px;
  }

  .margin-padding {
    padding-top: 20px;
  }
}
