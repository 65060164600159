.pro-heading {
  color: #292828;
}

.project-section {
  background-color: #f9fbfe;
}
.project-section-heading {
  color: #292828;
  font-size: 28px;
  font-weight: 700;
}
.project-section-description {
  color: #292828ca;
  font-size: 16px;
}
.project-card {
  text-align: center;
  background-color: white;
  border-style: solid;
  border-radius: 16px !important;
  margin: 10px;
  border-top-width: 10px !important;
  border-bottom: none;
  border-left: none;
  border-right: none;
  /*animation: project-animation 10s linear infinite; */
}
.project-card-image {
  width: 87px;
  height: 90px;
}
.project-card-title {
  color: #292828;
  font-size: 22px;
  font-weight: 500;
}
.project-card-description {
  color: #292828ca;
  font-size: 16px;
}

.project-card:hover > .project-content {
  opacity: 1;
  transform: scale(0.72);
  cursor: pointer;
  border-radius: 16px !important;
}

/*
.project-card:hover{
    animation-play-state: paused;
}
*/

.project-content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  width: 100%;
  background-color: #292828;
  color: white;
  border-radius: 16px !important;
  opacity: 0;
  transition: all 0.8s;
}

/*
@keyframes project-animation {

    0%{
        transform: rotate(-5deg);
    }
    25%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(5deg);
    }
    75%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-5deg);
    }
}
*/
