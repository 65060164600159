.edu-heading {
  color: #292828;
}
.college-section {
  background-color: #f9fbfe;
  border-right-width: 10px !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
}
.college-section-img {
  width: 270px;
  height: 270px;
}
.college-section-heading {
  color: #292828;
  font-size: 28px;
  font-weight: 700;
}
.college-section-description {
  color: #292828ca;
  font-size: 18px;
}
.custom-edu-button {
  color: white;
  background-color: #292828;
  width: 130px;
  height: 45px;
  border-width: 0;
  border-radius: 8px;
  margin-right: 10px;
}

/* Here we have colleges section (not college section) css property */
.colleges-section {
  background-color: white;
  border-left-width: 10px !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
}
.colleges-section-img {
  width: 270px;
}
.colleges-section-heading {
  color: #292828;
  font-size: 28px;
  font-weight: 700;
}
.colleges-section-description {
  color: #292828ca;
  font-size: 18px;
}

@media (max-width: 768px) {
  .college-section-content-container {
    text-align: center;
  }
}
