.heading {
  color: #292828;
}
.writing-section {
  background-color: #292828;
}
.writing-section-heading {
  color: #292828;
  font-size: 28px;
  font-weight: 700;
}
.writing-section-description {
  color: #292828ca;
  font-size: 16px;
}
.writing-card {
  text-align: center;
  background-color: white;
  border-style: solid;
  border-top-width: 10px !important;
  border-color: #e5eaf4;
  border-radius: 16px !important;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.writing-card-image {
  width: 87px;
  height: 90px;
}
.writing-card-title {
  color: #292828;
  font-size: 22px;
  font-weight: 500;
}
.writing-card-description {
  color: #292828ca;
  font-size: 16px;
}
.custom-button {
  color: white;
  background-color: #292828;
  width: 130px;
  height: 45px;
  border-width: 0;
  border-radius: 8px;
  margin-right: 10px;
}
