.footer {
  padding-top: 20px;
  margin-top: 20px;
}

.follow-me {
  background-color: white;
  text-align: center;
  color: #292828;
}

.design {
  background-color: #292828;
  height: 150px;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 10px;
  margin-top: 10px;
}

.footer-icons {
  color: #292828;
  font-size: 30px;
}

.footer-icons svg {
  color: white;
  font-size: 50px;
}

.footer-icons:hover {
  color: #292828;
  transform: scale(2);
  cursor: pointer;
}

.footer-section {
  background-color: #292828;
}
.footer-section-image {
  width: 60px;
  height: 60px;
  border-radius: 40px;
}
.footer-section-copyright-container {
  text-align: center;
  color: #ffffff;
  background-color: #1a1919;
  padding: 10px;
}
.footer-section-sub-heading {
  color: white;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: bold;
}
.footer-section-text {
  color: #9aa5b1;
  font-family: "Roboto";
  font-size: 14px;
}
.icon {
  color: #5a7184;
  font-size: 18px;
}
