.photo-heading {
  color: #292828;
}

.photo {
  padding: 20px;
  border: 1px solid #292828;
  border-radius: 10px;
  margin-top: 50px;
  animation: photo-animation 10s linear infinite;
}
.photo img {
  height: 220px;
  width: 100%;
}

.photo:hover {
  animation-play-state: paused;
  transform: scale(1.05);
  cursor: pointer;
  background-color: #292828;
  transform: scale(1.05);
  box-shadow: none;
}

@keyframes photo-animation {
  0% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}
